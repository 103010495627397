
import {
  Vue,
  Prop,
  Component,
} from "@shared/node_modules/vue-property-decorator";
import { Sport } from "@shared/crm/constants";

@Component
export default class SvgContainerSports extends Vue {
  @Prop({ default: 0 }) sport!: Sport;
  @Prop({ default: "black" }) fillColor!: string;

  public Sport = Sport;
}
