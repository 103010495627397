
import { BookingGender } from "@shared/core/constants";
import { DateHelper } from "@shared/core/helpers/dateHelper";
import { Format } from "@shared/core/helpers/formatHelper";
import store from "@shared/core/store";
import { Component, Vue, Prop } from "vue-property-decorator";
import { BookingConcept } from "@shared/crm/constants";
import SvgContainerSports from "@shared/crm/components/svgContainerSports.vue"

@Component({
  components: {
    SvgContainerSports
  },
})
export default class BookingByPlaceCard extends Vue {
  @Prop({ required: true, type: Object }) booking!: any;

  private get isEvent(){
    return this.booking.concept === BookingConcept.Event;
  }

  private get bookingPlayers() {
    return this.booking.players.slice(0, 6);
  }

  private get fourOrLessParticipants(){
    return this.booking.maxParticipants < 5;
  }

  private get sevenOrMoreParticipants(){
    return this.booking.maxParticipants > 6;
  }

  getImageType(logo: string) {
    if (logo !== undefined && logo !== "" && logo !== null) {
      return store.state.settings.Uploads + "/" + logo;
    }
  }
  async joinBooking(e: any, booking: any) {
    e.preventDefault();
    this.$emit("join-booking", e, booking);
  }

  getNamePlayer(player: any) {
    if (player.customerId === -1) {
      return "Libre";
    }
    return player.name.split(" ")[0];
  }

  formatDate(dateString: string) {
    const d: any = DateHelper.toDate(
      dateString,
      store.state.settings.DateFormat
    );
    const sdate = DateHelper.toServerFormat(d);
    if (sdate !== undefined) {
      return DateHelper.beautifyDate(sdate, true, false, true, false);
    }
  }

  getBookingGender(gender: Number) {
    switch (gender) {
      case BookingGender.Male:
        return this.$t("Male");
      case BookingGender.Female:
        return this.$t("Female");
      case BookingGender.Mixed:
        return this.$t("Mixed");
      case BookingGender.Open:
        return this.$t("Open");
      default:
        break;
    }
  }

  getBookingPrice(price: string, netPrice: string) {
    return store.state.settings.PriceWithTaxes
      ? Format.formatCurrency(price)
      : Format.formatCurrency(netPrice);
  }

  getMinMaxLevel(){
    let msg = "";
    if(this.booking.minLevel != null){
      msg += this.booking.minLevel.toString();
    }
    if(this.booking.maxLevel != null){
      if(msg !== ""){
        msg += " - ";
      }
      msg += this.booking.maxLevel.toString();
    }
    return msg;
  }
}
